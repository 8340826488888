"use client";

// We can not useState or useRef in a server component, which is why we are
// extracting this part out into it's own file with 'use client' on top
import { Provider } from "react-redux";
import { store } from "redux-store/store";

export default function Providers({ children }: { children: any }) {
	return <Provider store={store}>{children}</Provider>;
}
